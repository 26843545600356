<template>
  <div class="youth-home-stats" v-if="hasPerm('reports.view_')">
    <page-header
      title="Déclaration Conseil Départemental"
      icon="fas fa-chart-bar"
      :links="getLinks()"
    >
    </page-header>
    <div class="youth-report">
      <b-row>
        <b-col cols="9">
          <loading-gif :loading-name="statsLoading"></loading-gif>
          <b-row v-show="!isLoading(statsLoading) && showContent && !generateMode">
            <b-col cols="9">
              <date-range-selector @change="onDateRangeChanged" civil-first></date-range-selector>
            </b-col>
            <b-col cols="3">
              <div class="btn-holder">
                <a class="btn btn-primary btn-block" @click.prevent="loadData()" :class="{ disabled: !isLoadStatsActive, }">
                  Rafraîchir
                </a>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <generate-youth-home-stats
                v-show="!isLoading(statsLoading)"
                :start-date="startDate"
                :end-date="endDate"
                @generateMode="generateMode = $event.mode"
                @generating="disablePage"
                @generated="reloadPage"
              >
              </generate-youth-home-stats>
            </b-col>
          </b-row>
          <div v-if="!isLoading(statsLoading) && showContent" ref="printMe">
            <b-row >
              <b-col cols="12">
                <div class="stats" ref="excelMe">
                  <table class="table table-full table-bordered table-striped">
                    <tbody v-for="item of items" :key="item.group.id">
                      <tr>
                        <th colspan="3">{{ item.group.name }}</th>
                      </tr>
                      <tr>
                        <th>Mois</th>
                        <th class="text-center">Durée réelle (en équivalents journées)</th>
                      </tr>
                      <tr v-for="elt of item.table" :key="elt.key">
                        <th>{{ elt.monthName }}</th>
                        <td class="number text-center">{{ elt.realDuration }}</td>
                      </tr>
                    </tbody>
                  </table>
                  <hr />
                  <table class="table table-full table-bordered table-striped" v-if="excursionItems.length">
                    <tbody v-for="item of excursionItems" :key="item.group.id">
                      <tr>
                        <th colspan="3">{{ item.group.name }}</th>
                      </tr>
                      <tr>
                        <th>Séjour</th>
                        <th class="text-center">
                          Nombre de jours
                        </th>
                        <th class="text-center">
                          Nombre de jeunes
                        </th>
                      </tr>
                      <tr v-for="elt of item.table" :key="elt.key">
                        <th>{{ elt.name }}</th>
                        <td class="number text-center">{{ elt.days }}</td>
                        <td class="number text-center">{{ elt.individualsCount }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-col>
        <b-col cols="3">
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapMutations } from 'vuex'
import LoadingGif from '@/components/Controls/LoadingGif.vue'
import PageHeader from '@/components/Layout/PageHeader.vue'
import GenerateYouthHomeStats from '@/components/Stats/GenerateYouthHomeStats.vue'
import DateRangeSelector from '@/components/DateRange/DateRangeSelector.vue'
import { BackendMixin } from '@/mixins/backend'
import { makeAgeGroup } from '@/types/youth'
import { BackendApi, openDocument } from '@/utils/http'
import moment from 'moment'
import { dateToString } from '@/filters/texts'

export default {
  name: 'youth-reports',
  mixins: [BackendMixin],
  components: {
    LoadingGif, PageHeader, GenerateYouthHomeStats, DateRangeSelector,
  },
  props: {
    detail: {
      type: Boolean,
      defaultValue: false,
    },
  },
  data() {
    return {
      statsLoading: 'youth-reports',
      disabled: true,
      showContent: true,
      generateMode: false,
      startDate: null,
      endDate: null,
      fullData: null,
      durationMap: new Map(),
      groups: [],
      months: new Map(),
      excursionsNames: new Map(),
      excursionsMap: new Map(),
    }
  },
  computed: {
    isLoadStatsActive() {
      return this.startDate && this.endDate
    },
    items() {
      const items = []
      for (const group of this.groups) {
        const table = []
        for (const [month, monthName] of this.months.entries()) {
          const key = month + '#' + group.id
          let realDuration = 0
          let paidDuration = 0
          if (this.durationMap.has(key)) {
            const obj = this.durationMap.get(key)
            realDuration = obj.realDuration
            paidDuration = obj.paidDuration
          }
          table.push(
            {
              monthName: monthName,
              realDuration: realDuration,
              paidDuration: paidDuration,
            }
          )
        }
        items.push({ group: group, table: table, })
      }
      return items
    },
    excursionItems() {
      const items = []
      for (const group of this.groups) {
        const table = []
        for (const [code, name] of this.excursionsNames.entries()) {
          const key = code + '#' + group.id
          let days = 0
          let individualsCount = 0
          if (this.excursionsMap.has(key)) {
            const obj = this.excursionsMap.get(key)
            days = obj.days
            individualsCount = obj.individualsCount
          }
          table.push(
            {
              name: name,
              days: days,
              individualsCount: individualsCount,
            }
          )
        }
        items.push({ group: group, table: table, })
      }
      return items
    },
  },
  mounted() {
  },
  watch: {
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    async loadData() {
      this.startLoading(this.loadingName)
      await this.loadStats(this.detail)
      this.endLoading(this.loadingName)
    },
    async loadStats() {
      if (!this.isLoadStatsActive) {
        return
      }
      this.startDataDate = this.startDate
      this.endDataDate = this.endDate
      const url = '/reports/api/youth-report/?start_date=' + this.startDate + '&end_date=' + this.endDate
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.fullData = resp.data
        this.groups = resp.data.groups.map(elt => makeAgeGroup(elt))
        const months = new Map()
        const durationMap = new Map()
        const excursionsNames = new Map()
        const excursionsMap = new Map()
        for (const elt of resp.data['duration_by_group_and_month']) {
          const month = elt['month']
          const monthName = dateToString(month, 'MMMM YYYY')
          months.set(month, monthName)
          const key = month + '#' + elt['group_id']
          durationMap.set(
            key,
            {
              realDuration: elt['real_duration'],
              paidDuration: elt['paid_duration'],
            }
          )
        }
        for (const elt of resp.data['excursion_by_group']) {
          const code = elt['seance_code']
          const key = code + '#' + elt['group_id']
          excursionsNames.set(code, elt['name'])
          excursionsMap.set(
            key,
            {
              individualsCount: elt['individuals_count'],
              days: elt['days'],
            }
          )
        }
        this.months = months
        this.durationMap = durationMap
        this.excursionsMap = excursionsMap
        this.excursionsNames = excursionsNames
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    getLinks() {
      return [
        {
          id: 1,
          label: 'Pdf',
          callback: this.printMe,
          icon: 'fa fa-file-pdf',
          cssClass: (this.isLoading(this.statsLoading)) ? 'btn-secondary disabled' : 'btn-secondary',
        },
        {
          id: 2,
          label: 'Excel',
          callback: this.excelMe,
          icon: 'fa fa-file-excel',
          cssClass: (this.isLoading(this.statsLoading)) ? 'btn-secondary disabled' : 'btn-secondary',
        }
      ]
    },
    async printMe() {
      const docUrl = '/documents/standard/<key>/pdf/'
      const docSlug = 'declaration-departementale-' + moment().format('YYYY-MM-DD')
      const docContent = this.$refs.printMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent, 'dossier_caf')
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async excelMe() {
      const docUrl = '/documents/table-to-excel/<key>/'
      const docSlug = 'declaration-departementale-' + moment().format('YYYY-MM-DD')
      const docContent = this.$refs.excelMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    onDateRangeChanged(event) {
      this.startDate = event.startDate
      this.endDate = event.endDate
    },
    disablePage() {
      this.showContent = false
    },
    reloadPage() {
      this.showContent = true
      this.loadData()
    },
  },
}
</script>

<style scoped lang="less">
.number {
}
</style>
